import { Carousel } from 'react-responsive-carousel'
import "react-responsive-carousel/lib/styles/carousel.css"
import photos from './photos.json'

function Photos()
{
    return (
        <div class="photos">
            <p>Photos</p>
            <Carousel>
                {photos.map(photo => <Photo photo={photo} />)}
            </Carousel>
        </div>
    )
}

function Photo({photo})
{
    return (
        <div>
            <img src={photo.url} alt={photo.title} />
            <p>{photo.title}</p>
        </div>
    )
}

export default Photos
