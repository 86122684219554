import './Profile.css'

function Profile()
{
    return (
        <section id="profile">
            <div class="section_inner">
                <h1>I am</h1>
                <div class="flex">
                    <div class="profile_image">
                        <div class="illustration"></div>
                        <p class="sm">Illustration by ゆりゆりのゆり さん</p>
                    </div>
                    <div class="profile_comment">
                        <p class="b">Sora</p>
                        <p>Blog: <a href="https://blog.soora.jp" target="_blank" rel="noreferrer">https://blog.soora.jp</a></p>
                        <p>Twitter: <a href="https://twitter.com/soora_jp" target="_blank" rel="noreferrer">@soora_jp</a></p>
                        <p>GitHub: <a href="https://github.com/soora-jp" target="_blank" rel="noreferrer">@soora-jp</a></p>
                        <p>Qiita: <a href="https://qiita.com/soora_jp" target="_blank" rel="noreferrer">@soora_jp</a></p>
                        <p>TechBlog: <a href="https://note.soora.jp" target="_blank" rel="noreferrer">https://note.soora.jp</a></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Profile;
