import Photos from './photos/Photos.js'

function Favorites()
{
    return (
        <section id="favorites">
            <div class="section_inner">
                <h1>My favorite</h1>
                <Photos />
            </div>
            
        </section>
    )
}

export default Favorites
