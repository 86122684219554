import './App.css';
import Profile from './sections/profile/Profile.js'
import Works from './sections/works/Works.js'
import Blog from './sections/blog/Blog.js'
import Favorites from './sections/favorites/Favorites.js'

function App()
{
  return (
    <div className="App">
      <Profile />
      <Works />
      <Blog />
      <Favorites />
    </div>
  );
}

export default App;
