import './Works.css'
import mades from './mades.json'
import maintains from './maintains.json'

function Works()
{
    return (
        <section id="works">
            <div class="section_inner">
                <h1>I made</h1>
                <Mades />
                <p>and maintain</p>
                <Maintains />
                <p>servers</p>
            </div>
        </section>
    )
}

function Mades()
{
    return (
        <div class="works flextile">
        {
            mades.map(made => {
                return <Made made={made} />
            })
        }
        </div>
    )
}

function Made({made})
{
    return (
        <div class="work tile">
            <h2>{made.link == null ? made.name : (<a href="{made.link}" target="_blank">{made.name}</a>)}</h2>
            <div class="tech">{made.tech.join(' + ')}</div>
        </div>
    )
}

function Maintains()
{
    return (
        <div class="works flextile">
        {
            maintains.map(maintain => {
                return <Maintain maintain={maintain} />
            })
        }
        </div>
    )
}

function Maintain({maintain})
{
    return (
        <div class="work tile">
            <h2>{maintain.name}</h2>
            <div class="tech">{maintain.tech.join(' ')}</div>
        </div>
    )
}

export default Works;
