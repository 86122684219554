import React from 'react'
import axios from 'axios'
import './Blog.css'

function Blog()
{
    const [state, setState] = React.useState(true);
    const endpoint = 'https://blog.soora.jp/index.php?rest_route=/wp/v2/posts';

    axios.get(endpoint)
    .then(res => {
        var articles = [];
        res.data = res.data.slice(0, 8);
        res.data.forEach(article_raw => {

            var d = new Date(article_raw.date);
            var dateStr = "";
            dateStr += d.getFullYear() + '/';
            dateStr += ("0" + (d.getMonth()+1)).slice(-2) + '/';
            dateStr += ("0" + d.getDate()).slice(-2) + ' ';
            dateStr += ("0" + d.getHours()).slice(-2) + ':';
            dateStr += ("0" + d.getMinutes()).slice(-2);

            const article = {
                title: article_raw.title.rendered,
                date: dateStr,
                link: article_raw.link
            };
            articles.push(article);
        });
        setState(articles);
    })
    .catch(error => {
        setState(false);
    });

    return (
        <section id="blog">
            <div class="section_inner">
                <h1>I wrote</h1>
                <Articles articles={state}/>
                <p>on my blog</p>
            </div>
        </section>
    )
}

function Articles({articles})
{
    if(articles === true)
    {
        return <p>Loading blog articles</p>;
    }
    else if(articles === false)
    {
        return <p>Failed to load blog articles</p>;
    }
    else
    {
        return (
            <div class="articles flextile">
                {articles.map(article => <Article article={article} />)}
            </div>
        );
    }
}

function Article({article})
{
    return (
        <div class="article tile">
            <div class="article_title"><a href="article.link">{ article.title }</a></div>
            <div class="article_date">{article.date}</div>
        </div>
    );
}

export default Blog;
